import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './Components/Home/Home';
import Privacy from './Components/Privacy/Privacy';
import Terms from './Components/Terms/Terms';
import './App.scss';
import StandWithUkraine from "./Components/StandWithUkraine/StandWithUkraine";

const App = () => (
  <div className="App">
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/stand-with-ukraine" exact component={StandWithUkraine} />
      <Route path="/privacy" exact component={Privacy} />
      <Route path="/terms" exact component={Terms} />
    </Switch>
  </div>
);

export default App;
