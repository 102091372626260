import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';

import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import './ContactForm.scss';

import Select from 'react-select';

const options = [
  { value: '<5000', label: '<5000' },
  { value: '10000-50000', label: '10000-50000' },
  { value: '50000-100000', label: '50000-100000' },
  { value: '>100000', label: '>100000' },
];

const options2 = [
  { value: 'Affiliate', label: 'Afiliado' },
  { value: 'Advertiser', label: 'Anunciante' },
];

const messenger = [
  { value: 'Telegram', label: 'Telegram' },
  { value: 'Viber', label: 'Viber' },
  { value: 'Whatsapp', label: 'Whatsapp' },
  { value: 'Skype', label: 'Skype' },
];

const vertical = [
  { value: 'Mobile content', label: 'Contenido móvil' },
  { value: 'Downloads', label: 'Descargas' },
  { value: 'Nutra (SS/Trial)', label: 'Nutra (SS/Prueba, COD)' },
  { value: 'Sorteo', label: 'Sorteo' },
  { value: 'Forex/Crypto', label: 'Forex/Crypto' },
  { value: 'Citas', label: 'Citas' },
  { value: 'Juegos de azar', label: 'Juegos de azar' },
  { value: 'Otro', label: 'Otro' },
];

const traffic = [
  { value: 'Pop', label: 'Pop' },
  { value: 'Apretar', label: 'Apretar' },
  { value: 'Social', label: 'Social' },
  { value: 'Buscar', label: 'Buscar' },
  { value: 'Mostrar', label: 'Mostrar' },
  { value: 'Redirigir', label: 'Redirigir' },
  { value: 'Nativo', label: 'Nativo' },
  { value: 'SMS', label: 'SMS' },
  { value: 'Otro', label: 'Otro' },
];

const ContactForm = () => {
  const mailValidation = /^.+@.+\..+$/igm;

  const [fullName, setFullName] = useState("");
  const [errorFullName, setErrorFullName] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [errorCompanyName, setErrorCompanyName] = useState(false);

  const [email, setEmail] = useState("");
  const [errorMail, setErrorMail] = useState(false);

  const [tel, setTel] = useState("");
  const [errorTel, setErrorTel] = useState(false);

  const [webSite, setWebSite] = useState("");
  const [errorWebSite, setErrorWebSite] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");
  const [errorSelect, setErrorSelect] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState("");

  const [messengerOption, setMessengerOption] = useState("");
  const [errorMessengerOption, setErrorMessengerOption] = useState(false);

  const [messengerId, setMessengerId] = useState("");
  const [errorMessengerId, setErrorMessengerId] = useState(false);

  const [verticalOption, setVerticalOption] = useState("");
  const [errorVerticalOption, setErrorVerticalOption] = useState(false);

  const [trafficOption, setTrafficOption] = useState("");
  const [errorTrafficOption, setErrorTrafficOption] = useState(false);

  useEffect(() => {
    if (trafficOption !== null && trafficOption.length !== 0) {
      setErrorTrafficOption(false)
    }
  }, [trafficOption]);

  useEffect(() => {
    if (verticalOption.length !== 0) {
      setErrorVerticalOption(false)
    }
  }, [verticalOption]);

  useEffect(() => {
    if (messengerId.length > 1) {
      setErrorMessengerId(false)
    }
  }, [messengerId]);

  useEffect(() => {
    if (messengerOption.length !== 0) {
      setErrorMessengerOption(false)
    }
  }, [messengerOption]);

  useEffect(() => {
    if (selectedOption.length !== 0) {
      setErrorSelect(false)
    }
  }, [selectedOption]);

  useEffect(() => {
    if (webSite.length > 1) {
      setErrorWebSite(false)
    }
  }, [webSite]);

  useEffect(() => {
    if (fullName.length > 1) {
      setErrorFullName(false)
    }
  }, [fullName]);

  useEffect(() => {
    if (companyName.length !== 0) {
      setErrorCompanyName(false)
    }
  }, [companyName]);

  useEffect(() => {
    if (mailValidation.test(email)) {
      setErrorMail(false)
    }
  }, [email, mailValidation]);

  useEffect(() => {
    if (isValidPhoneNumber(tel)) {
      setErrorTel(false)
    }
  }, [tel]);



/*ip info*/
  const [ipInfo, setIpInfo] = useState();

  /*
  const getUsers = () => {
    return fetch(`https://freegeoip.app/json/`)
      .then(response => response.json());
  };
  */

  const getUsers = () => {
    return fetch(`https://ipinfo.io/json`)
      .then(response => response.json());
  };

 useEffect(() => {
  getUsers()
    .then(goods => setIpInfo(goods));
  }, []);

  const [ref] = useState(document.referrer);

  //get param "p" from url
  const getRefAffiliateCode = () => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const refAffCode = urlParams.get('p');
    return refAffCode;
  };

  /*ip info*/

  const submit = () => {
    if (fullName.length < 1) {
      setErrorFullName(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (companyName.length === 0) {
      setErrorCompanyName(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (!mailValidation.test(email)) {
      setErrorMail(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (isValidPhoneNumber(tel) === false) {
      setErrorTel(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (webSite.length < 1) {
      setErrorWebSite(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (messengerId.length < 1) {
      setErrorMessengerId(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (selectedOption.length === 0) {
      setErrorSelect(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (messengerOption.length === 0) {
      setErrorMessengerOption(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (verticalOption.length === 0) {
      setErrorVerticalOption(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (trafficOption === null || trafficOption.length === 0) {
      setErrorTrafficOption(true);
      nameRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    if (fullName.length >= 1
      && selectedOption.length
      && messengerOption.length !== 0
      && verticalOption.length !== 0
      && companyName.length !== 0
      && webSite.length >= 1
      && messengerId.length >= 1
      && mailValidation.test(email)
      && (trafficOption !== null && trafficOption.length !== 0)
      && isValidPhoneNumber(tel)) {
      setSubmitting(true);
      const value = [];
      trafficOption.forEach(element => {
        value.push(element.value)
      });
      const sendValue = value.join(",");
      const DATA = JSON.stringify({
        fullName: fullName,
        typeOfBusiness: companyName,
        tel: tel,
        mail: email,
        webSite: webSite,
        monthlyVolume: selectedOption,
        messenger: messengerOption,
        messengerId: messengerId,
        verticalOption: verticalOption,
        trafficOption: sendValue,
        countryCode: ipInfo && ipInfo["country"],
        affiliateReferrer: getRefAffiliateCode(),
        ip: ipInfo && ipInfo["ip"],
        ref: ref,
      })

      axios.post('/contact.php', DATA)
      .then(function () {
        setTimeout(() => {
          setSuccessSubmit("1")
        }, 500)
      })
      .catch(function () {
        setTimeout(() => {
          setSuccessSubmit("2")
        }, 500)
      });
    }
  }

  let nameRef = useRef();

  return (
  <form className="contact-form" ref={nameRef}>
    {!submitting
    ?
    <>
    <div className="contact-form_inner">
      <span className={errorFullName ? "error-span-left active" : "error-span-left"}>Introduzca nombre completo</span>
      <input onChange={(e) => setFullName(e.target.value)} className="contact-form_input" placeholder="Nombre completo"></input>
      <span className={errorCompanyName ? "error-span-right active" : "error-span-right"}>Seleccione el tipo de negocio:</span>
      <Select
        placeholder="Tipo de negocio:"
        value={companyName.value}
        onChange={(e) => setCompanyName(e.value)}
        options={options2}
        classNamePrefix="react-select"
      />
    </div>

    <div className="contact-form_inner">
      <span className={errorMail ? "error-span-left active" : "error-span-left"}>Introduzca un correo electrónico válido</span>
      <input onChange={(e) => setEmail(e.target.value)} className="contact-form_input" placeholder="Dirección del correo electrónico"></input>
      <span className={errorTel ? "error-span-right active" : "error-span-right"}>Ingrese el número de teléfono correcto</span>
      <PhoneInput
      flags={flags}
      international
      defaultCountry="UA"
      value={tel}
      className="input-tel"
      onChange={setTel}
      />
    </div>

    <div className="contact-form_inner">
      <span className={errorWebSite ? "error-span-left active" : "error-span-left"}>Entrar en el sitio web de la empresa</span>
      <input onChange={(e) => setWebSite(e.target.value)} className="contact-form_input" type="name" placeholder="Página Web de la empresa"></input>
      <span className={errorSelect ? "error-span-right active" : "error-span-right"}>Seleccione Volumen mensual esperado</span>
      <Select
        placeholder="Volumen mensual esperado $"
        value={selectedOption.value}
        onChange={(e) => setSelectedOption(e.value)}
        options={options}
        classNamePrefix="react-select"
      />
    </div>

    <div className="contact-form_inner">
      <span className={errorMessengerOption ? "error-span-left active" : "error-span-left"}>Elige mensajero</span>
      <Select
        placeholder="Mensajero"
        value={messengerOption.value}
        onChange={(e) => setMessengerOption(e.value)}
        options={messenger}
        classNamePrefix="react-select"
      />
      <span className={errorMessengerId ? "error-span-right active" : "error-span-right"}>Ingrese la identificación del mensajero</span>
      <input onChange={(e) => setMessengerId(e.target.value)} className="contact-form_input messenger-id" type="id" placeholder="ID de Mensajero"></input>
    </div>




    <div className="contact-form_inner">
      <span className={errorVerticalOption ? "error-span-left active" : "error-span-left"}>Seleccionar vertical</span>
      <Select
        placeholder="Vertical"
        value={verticalOption.value}
        onChange={(e) => setVerticalOption(e.value)}
        options={vertical}
        classNamePrefix="react-select"
      />
      <span className={errorTrafficOption ? "error-span-right active" : "error-span-right"}>Seleccionar fuente de tráfico</span>
      <Select
        placeholder="Fuentes de tráfico"
        value={trafficOption}
        onChange={setTrafficOption}
        options={traffic}
        isMulti={true}
        className="traffic-input"
        classNamePrefix="react-select"
      />
    </div>





    <button onClick={submit} type="button" className="contact-form_button">Enviar</button>
    </>
    :
    <div className="submit-window">
      {(successSubmit !== "1" && successSubmit !== "2")
      &&
      <>
      <span className="submit-window_text">Enviar el formulario</span>
      <div className="lds-ripple"><div></div><div></div></div>
      </>
      }
      {successSubmit === "1"
      &&
      <>
      <span className="submit-window_text">¡El envío fue exitoso!</span>
      </>
      }
      {successSubmit === "2"
      &&
      <>
      <span className="submit-window_text">¡Enviar error!</span>
      <span className="submit-window_text">Inténtalo de nuevo</span>
      </>
      }
    </div>
    }
    </form>
  );
}

export default ContactForm;
