import React, {useEffect} from 'react';
import {
    Link
} from "react-router-dom";


import './StandWithUkraine.scss';

const StandWithUkraine = () => {

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <section className="stand-with-ukraine">
            <div className="stand-with-ukraine_inner">
                <div className="stand-with-ukraine_wrap">
                    <div className="stand-with-ukraine_logo">
                        <img src='/img/logo2.png' alt="logo"/>
                        <img className="stand-with-ukraine_flag" src='/img/flag.png' alt="logo"/>
                    </div>
                    <div className="stand-with-ukraine_link">
                        <a href="/#contact" className="with-ukraine_link">Sign Up</a>
                    </div>
                </div>

                <div className="stand-with-ukraine_content">
                    <h1>We are CMaffiliates, Ukraine-based company.</h1>

                    <p>
                        During these tough times of Russian’s aggression all of the world stands with Ukraine, the
                        country
                        has become not only Europe’s fore post, but the stronghold for all democratic world against
                        repressions and tyranny.
                    </p>

                    <p>
                        We call for everyone, who can not stay aside, and want to participate and help Ukraine win.
                    </p>
                    <p>
                        Please follow the links and find your convenient way to Help Ukraine:
                        <br/>
                        <a href="https://eu-ua.com/" target={"_blank"} rel="noopener">eu-ua.com</a>
                        <br/>
                        <a href="https://www.comebackalive.in.ua/" target={"_blank"} rel="noopener">comebackalive.in.ua</a>
                    </p>
                    <p>
                        If you know how to run: gambling, forex/crypto, dating, sweepstakes, leadgen, nutra
                        offers, please <strong> just sign up</strong>, and our manager
                        will contact you.
                        If you believe that you cannot stay aside and want to help, just write to our managers
                        “standWithUkraine”, this way you show that you are ready to provide 3% of your profits from
                        running
                        campaigns with CMaffiliates to those who are desperately in need during these dark times of
                        European
                        history.
                        Let’s unite and become stronger!
                    </p>
                    <p>
                        Work with Ukraine to Stand with Ukraine.
                    </p>
                </div>


            </div>
        </section>
    );
}

export default StandWithUkraine;
