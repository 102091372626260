import React, { useState } from 'react';
import ContactForm from '../ContactForm/ContactForm';
import {
  Link
} from "react-router-dom";
import Hamburger from 'hamburger-react'

import './Home.scss';

const Home = () => {

  const [isOpen, setOpen] = useState(false);

  return (
  <div className="home">
    <div className=" flex full-center with-ukraine">
      <i className="flag-icon flag-icon-ua with-ukraine_flag" ></i>
      Work with Ukraine <br/> to Stand with Ukraine!
      <Link to="/stand-with-ukraine"  className={"with-ukraine_link"}>
        see more
      </Link>
    </div>


    <header className="header">
    <nav className="header_nav">
        <a href="/">
          <img className="header_logo" src='/img/logo.png' alt="logo"></img>
        </a>
        <ul className="header_links">
          <li>
            <a href="#affiliates" className="header_link">Afiliados</a>
          </li>
          <li>
            <a href="#advertisers" className="header_link">Anunciantes</a>
          </li>
          <li>
            <a href="#who" className="header_link">Quiénes somos</a>
          </li>
          <li>
            <a href="#contact" className="header_link">Contáctenos</a>
          </li>
          {/*
          <li>
            <a href="https://cmaffs.com/blog" className="header_link header_link_last">blog</a>
          </li>
          */}
            <a href="https://cmaffiliates.everflowclient.io" target="_blank" id="header_link_inner" className="header_link_inner">acceso</a>
            <div className="header_link_language">
              {/*<a href="https://ru.cmaffs.com/">RU</a>*/}
              {/*<a className="link-line">|</a>*/}
              <a className="active-link" href="https://es.cmaffs.com/">ES</a>
              <a className="link-line">|</a>
              <a href="https://cmaffs.com/">EN</a>
           </div>
           <div className="hamburger-menu">
            <Hamburger color="white" toggled={isOpen} toggle={setOpen} />
          </div>
        </ul>
        <a href="https://cmaffiliates.everflowclient.io" target="_blank" id="header_link_inner-mobile" className="header_link_inner">acceso</a>
      </nav>

      <div className={isOpen ? "mobile_hamburger-menu mobile_hamburger-menu-active": "mobile_hamburger-menu"}>
      <ul className="header_hamburger-links">
      <div className="hamburger-menu-mobile">
            <Hamburger color="white" toggled={isOpen} toggle={setOpen} />
          </div>
          <li>
            <a href="/"><img className="header_logo-menu" src='/img/logo.png' alt="logo"></img></a>
          </li>
          <li>
            <a href="#affiliates" className="header_link mobile-link">Afiliados</a>
          </li>
          <li>
            <a href="#advertisers" className="header_link mobile-link">Anunciantes</a>
          </li>
          <li>
            <a href="#who" className="header_link mobile-link">Quiénes somos</a>
          </li>
          <li>
            <a href="#contact" className="header_link mobile-link">Contáctenos</a>
          </li>
          {/*
          <li>
            <a href="https://cmaffs.com/blog" className="header_link header_link_last mobile-link">blog</a>
          </li>
          */}
        </ul>
    </div>

      <div className="header_inner">
        <h1 className="header_inner-article">Las estrellas en ascenso</h1>
        <h4 className="header_inner-text">Somos mediadores del ecosistema de adquisición de medios, ayudando a afiliados individuales y equipos de tamaño medio a alcanzar alta rentabilidad de la inversión (ROI) en los tiempos difíciles de constantes cambios en la industria del cpa marketing de afiliación.</h4>
        <a href="#contact" className="header_inner-button">Regístrese con nosotros</a>
      </div>
    </header>



    <section id="affiliates" className="affiliates">
      <div className="affiliates_top">
        <img className="affiliates_top-img" src="/img/background2.png" alt="logo"></img>
        <div className="affiliates_top-inner">
          <h2 className="affiliates_top-inner-article">AFILIADOS</h2>
          <h3 className="affiliates_top-inner-text">Sus altas expectativas recibirán el verdadero enfoque profesional</h3>
        {/*<h3 className="affiliates_top-inner-text">самые дерзкие ожидания партнёров</h3>*/}
        </div>
      </div>
      <div className="affiliates_bottom">
        <div className="affiliates_bottom-left">
          <div className="affiliates_bottom-decoration"></div>
          <p className="affiliates_bottom-left-text">Flujos, ángulos, creatividad y redacción publicitaria de Plug & Play. Lo proporcionamos todo simplificando al máximo la vida de los afiliados. Conversión y obtención de alta rentabilidad de la inversión. Simplemente copie y pegue y ejecute.
</p>
          <div className="affiliates_bottom-decoration"></div>
          <p className="affiliates_bottom-left-text">Ofertas y tratos “Unicornio”, que sería difícil encontrar en cualquier otro lado. Nos esforzamos al máximo por conseguir ofertas exclusivas para usted.

</p>
        </div>
        <div className="affiliates_bottom-center"></div>
        <div className="affiliates_bottom-right">
          <div className="affiliates_bottom-decoration"></div>
          <p className="affiliates_bottom-right-text">
No hay un Socio que ayude a los afiliados tanto como nosotros: soluciones de pago, cuentas, finanzas y cualquier tipo de consulta que usted pueda necesitar.
</p>
          <div className="affiliates_bottom-decoration"></div>
          <p className="affiliates_bottom-right-text">No somos para todos, sino solo para unos pocos.

</p>
        </div>
      </div>
    </section>



    <section className="who" id="who">
      <div className="who_inner">
        <h2 className="who_inner-article">Quienes somos</h2>
        <p className="who_inner-text">Somos un equipo de profesionales de comercialización del afiliado, EL equipo que se encarga de todos los trabajos sucios por usted, con poco o ningún esfuerzo por su parte. Lo más difícil que podría usted estar haciendo para cooperar con nosotros es ver cómo sus ganancias se disparan.</p>
      </div>
      <img className="who_img" src='img/who-img.png' alt="who img"></img>
    </section>



    <section className="advertisers" id="advertisers">
      <div className="advertisers_inner">
        <h2 className="advertisers_inner-article">ANUNCIANTES</h2>
        <p className="advertisers_inner-text">Entrega de grandes volúmenes de tráfico premium directamente a ofertas exclusivas.
</p>
      </div>
    </section>



    <section className="advertisers-cards">
      <div className="advertisers-cards_card">
        <img className="advertisers-cards_card-img" src='/img/graphic.png' alt="graphic"></img>
        <h3 className="advertisers-cards_card-article">TRÁFICO DE CALIDAD SUPREMA</h3>
        <p className="advertisers-cards_card-text">Hemos pasado bastante tiempo construyendo una red de editores, seleccionando cuidadosamente a cada uno de ellos. Por lo tanto, estamos seguros al 100% del tráfico que se le envía a usted.</p>
      </div>
      <div className="advertisers-cards_card center-card">
        <img className="advertisers-cards_card-img" src='/img/like.png' alt="like"></img>
        <h3 className="advertisers-cards_card-article">USTED ES EL # 1</h3>
        <p className="advertisers-cards_card-text">Proporcionamos la mayoría de los tipos de tráfico de calidad suprema de nuestra red de editores de confianza. Sabemos qué es los que funciona mejor para su producto, así que siéntese y observe esas conversiones que generan un LTV alto con cero devoluciones de pago.</p>
      </div>
      <div className="advertisers-cards_card">
        <img className="advertisers-cards_card-img" src='/img/distance.png' alt="distance"></img>
        <h3 className="advertisers-cards_card-article">SIMPLIFICANDO LA COMPLEJIDAD</h3>
        <p className="advertisers-cards_card-text">Hemos estado en este camino durante mucho tiempo, por lo que conocemos los atajos y cómo simplificar procesos complejos para que fluyan sin contratiempo alguno. Por lo tanto, nuestro equipo de gerentes profesionales hará todo el trabajo para que usted pueda disfrutar del proceso de conteo de ganancias.</p>
      </div>
    </section>



    <section className="promote">
      <h2 className="promote_article">CÓMO PROMOVER CON CMAFFILIATES</h2>
      <div className="promote_inner">
        <div className="promote_inner-top">
          <div className="promote_card">
            <img className="promote_card-img" src="/img/1.png" alt="num 1"></img>
            <div className="promote_card-inner">
              <h4 className="promote_card-article">REGISTRARSE</h4>
              <span className="promote_card-text">Regístrese en la red,<br></br>u Obtenga sus credenciales de un gerente.</span>
            </div>
          </div>
          <div className="promote_card-decoration">
            <div className="promote_card-decoration-circle"></div>
            <div className="promote_card-decoration-circle"></div>
            <div className="promote_card-decoration-circle"></div>
          </div>
          <div className="promote_card mrtop">
            <img className="promote_card-img" src="/img/2.png" alt="num 2"></img>
            <div className="promote_card-inner">
              <h4 className="promote_card-article">CONFIGURACIÓN</h4>
              <span className="promote_card-text">Espere hasta que la configuración esté lista para usted,<br></br>Obtenga creatividades, promociones y LP.</span>
            </div>
          </div>
        </div>
        <div className="promote_card-decoration middle">
          <div className="promote_card-decoration-circle"></div>
          <div className="promote_card-decoration-circle"></div>
          <div className="promote_card-decoration-circle"></div>
        </div>
        <div className="promote_inner-bottom">
          <div className="promote_card  mrtop">
          <img className="promote_card-img" src="/img/4.png" alt="num 4"></img>
            <div className="promote_card-inner">
              <h4 className="promote_card-article">BENEFICIOS</h4>
              <span className="promote_card-text">Cuente sus ganancias.</span>
            </div>
          </div>
          <div className="promote_card-decoration">
            <div className="promote_card-decoration-circle"></div>
            <div className="promote_card-decoration-circle"></div>
            <div className="promote_card-decoration-circle"></div>
          </div>
          <div className="promote_card">
            <img className="promote_card-img" src="/img/3.png" alt="num 3"></img>
            <div className="promote_card-inner">
              <h4 className="promote_card-article">OPTIMIZAR</h4>
              <span className="promote_card-text">Lance campañas, optimice.</span>
            </div>
          </div>
        </div>
        <div className="promote_card-decoration bottom">
          <div className="promote_card-decoration-circle"></div>
          <div className="promote_card-decoration-circle"></div>
          <div className="promote_card-decoration-circle"></div>
        </div>
      </div>
    </section>



  <section className="contact">
    <div className="contact_inner">
      <div className="contact_decoration test"></div>
      <div id="contact" className="contact_form">
        <h2 className="contact_form-article">CONTÁCTENOS</h2>
        <span className="contact_form-text">Complete el formulario CONTÁCTENOS y nos pondremos en contacto con usted</span>
        <ContactForm />
        <span className="contact_form-text contact_form-contact">Para consultas generales, favor contactarnos por <a className="contact_form-contact-link" href="mailto:general@cmaffs.com">general@cmaffs.com</a></span>
      </div>
    </div>
  </section>

  <footer className="footer">
    <Link className="footer_link" to="/privacy">Privacy Policy</Link>
    <Link className="footer_link" to="/terms">Terms and Conditions</Link>
    <span className="footer_span">CMAffiliates © 2022</span>
  </footer>

  </div>
);
}

export default Home;
